import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import { graphql } from "gatsby"

import { serializers } from "../utils/blocks"
import PageHead from "../components/PageHead"
import Layout from "../components/Layouts/Base"
import SEO from "../components/seo"

export const query = graphql`
  query SinglePageQuery($slug: String) {
    sanityPage(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      heading
      _rawBody
      seoTitle
      seoDescription
    }
  }
`

const PageTemplate = ({ data }) => {
  const { heading, seoTitle, seoDescription, _rawBody } = data.sanityPage
  return (
    <Layout>
      <SEO title={seoTitle || heading} description={seoDescription} />

      <PageHead>{heading}</PageHead>

      <BlockContent
        className="text-container mb-16 md:mb-28"
        blocks={_rawBody}
        serializers={serializers}
        projectId={process.env.GATSBY_SANITY_PROJECT}
        dataset={process.env.GATSBY_SANITY_DATASET}
      />
    </Layout>
  )
}

export default PageTemplate
