import React from "react"

const PageHead = ({ children, className }) => {
  return (
    <div className={`py-6 md:py-12 text-container ${className}`}>
      <h1
        itemProp="name headline"
        className="w-full font-display text-2xl md:text-4xl font-bold text-black"
      >
        {children}
      </h1>
    </div>
  )
}

export default PageHead
